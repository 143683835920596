import React from 'react';
import styled from 'styled-components';
import SEO from '../components/SEO';
import Container from '../components/Layout/Container';

const StyledAbout = styled.section`
  margin: 1.5rem 0;

  h1 {
    margin-top: 0;
  }

  img {
    width: 300px;
    border-radius: 5px;
    margin: 0rem 1rem 0 0;
  }

  @media (max-width: 576px) {
    img {
      width: 100%;
      float: none;
    }
  }
`;

export default function About() {
  return (
    <StyledAbout>
      <SEO title="Quiénes somos" />
      <Container>
        <h1>Nuestra historia</h1>
        <p>
          Nuestro blog se ha ido cocinando poco a poco, a
          fuego lento, como los buenos sofritos. La idea nació hace mucho tiempo
          y, aunque la familia y amigos nos animaban, siempre salía algún
          impedimento que hacía que quedase arrinconado en la despensa.
        </p>
        <p>
          ¡Pero ahora ha llegado el momento! Mi hijo mayor Jaume es el súper
          webmaster que ha tirado del carro por todos nosotros. Jaime, mi
          marido, es el &quot;cocinero&quot; de imágenes (más que perfeccionista) y mi
          hijo pequeño Roger, el &quot;gourmet&quot; oficial de todos mis platos. Yo, Mari
          Carmen, soy la cocinera y responsable desde hace años del comedor de
          nuestro hogar.
        </p>
        <h2>Nuestro objetivo</h2>
        <p>
          Con este blog solo queremos enseñar que la cocina hecha con unos
          buenos ingredientes y con mucho amor, tiene una gran recompensa que
          llega cuando te sientas a la mesa con la familia y/o amigos a
          disfrutar de un buen plato, por sencillo que sea.
        </p>
        <h2>En los fogones</h2>
        <img src="/chef.jpg" alt="Nuestra master chef" />
        <p>
          Como cocinera os diré que, desde pequeña, las cocinitas me traían de
          cabeza, y cuando pude empezar a hacerlo (bueno, a intentarlo) ya no
          paré. Aún recuerdo mis primeras magdalenas recién salidas del horno,
          una gran presencia pero ¡¡¡más duras que las piedras!!!
        </p>
        <p>
          Me gusta cocinar, me relaja cuando puedo hacerlo sin prisas. Me gustan
          los programas de cocina, buscar por Internet, en libros... Donde hay
          una receta allí estoy yo leyendo, y luego a probar a ver qué tal,
          siempre dando un toque personal.
        </p>
        <p>
          Esperamos que este blog os sea de ayuda para los que empezáis a
          cocinar, y a los que ya sabéis, seguro que alguna idea podréis añadir
          a vuestro libro personal de recetas.
        </p>
        <p>¿Empezamos?</p>
      </Container>
    </StyledAbout>
  );
}
